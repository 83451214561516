function fadeInPage() {
  if (!window.AnimationEvent) { return; }
  var fader = document.getElementById('fader');
  fader.classList.add('fade-out');
}

if (window.AnimationEvent) {
  document.addEventListener('DOMContentLoaded', function () {

    $('a.page-transition').each(function () {
      if ($(this)[0].hostname !== window.location.hostname) {
        return;
      }
      $(this).click(function (event) {
        var fader = document.getElementById('fader'),
          anchor = event.currentTarget;

        var listener = function () {
          window.location = anchor.href;
          fader.removeEventListener('animationend', listener);
        };
        fader.addEventListener('animationend', listener);

        event.preventDefault();
        fader.classList.add('fade-in');
      });
    });
  });
}
window.addEventListener('pageshow', function (event) {
  if (!event.persisted) {
    return;
  }
  var fader = document.getElementById('fader');
  fader.classList.remove('fade-in');
});